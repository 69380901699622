import { useCallback, useState } from "react";
import type { Authentication } from "../types/authentication";
import type { Checkout } from "../types/checkout";
import useLocalStorage from "../utils/localstorage";

export default function usePromo(authentication: Authentication, checkout: Checkout, handleCheckout: () => Promise<void>, outlet_code: string) {
  const [state, setState] = useLocalStorage("promo-code", "");
  const [loading, setLoading] = useState(false);

  const cancel = useCallback(async () => {
    setLoading(true);
    return await fetch(process.env.REACT_APP_API_URL + "/api/pos-order/outlet-service/cancel-promo", {
      body: JSON.stringify({ outlet_code, phone: authentication.phoneNumber || null, promo_code: state }),
      headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") setState("");
        else setState(state);
      })
      .catch((error) => {
        console.log(error);
        setState(state);
      })
      .finally(() => {
        handleCheckout();
        setLoading(false);
      });
  }, [authentication.phoneNumber, authentication.token.access, authentication.token.type, handleCheckout, outlet_code, setState, state]);

  const use = useCallback(
    async (promo_code: string) => {
      setLoading(true);
      return await fetch(process.env.REACT_APP_API_URL + "/api/pos-order/outlet-service/use-promo", {
        body: JSON.stringify({
          item:
            checkout.products.map((product) => ({
              id_brand: product.id_brand,
              id_custom: product.id_custom,
              id_product: product.id,
              product_code: product.code,
              product_name: product.name,
              product_price: product.price,
              product_price_total: product.price_total,
              qty: product.quantity,
            })) || [],
          item_service:
            checkout.services.map((service) => ({
              booking_date: service.date,
              id_brand: service.id_brand,
              id_custom: service.id_custom,
              id_product: service.id,
              product_code: service.code,
              product_name: service.name,
              product_price: service.price,
              qty: service.quantity,
            })) || [],
          outlet_code,
          phone: authentication.phoneNumber || null,
          promo_code,
        }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          setState(data.result.promo_code);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          handleCheckout();
          setLoading(false);
        });
    },
    [
      authentication.phoneNumber,
      authentication.token.access,
      authentication.token.type,
      checkout.products,
      checkout.services,
      handleCheckout,
      outlet_code,
      setState,
    ]
  );

  const reset = () => setState("");

  return { code: state, loading, handle: { cancel, reset, use } };
}

import type { FC } from "react";
import type { PaymentDetailType } from "../types/components";
import "../styles/components/payment-detail.css";

const PaymentDetail: FC<PaymentDetailType> = (props) => (
  <table className="component-payment-detail">
    <thead>
      <tr style={{ verticalAlign: "top" }}>
        <th className="ion-text-start" colSpan={3} scope="col">
          {props.category}
        </th>
        <th className="ion-text-end" scope="col">
          JUMLAH ({props.currency})
        </th>
      </tr>
    </thead>
    <tbody>
      {props.data.map((item, index) => (
        <tr key={index} style={{ verticalAlign: "top" }}>
          <td style={{ width: "1%" }}>{item.quantity}</td>
          <td style={{ width: "1%" }}>x</td>
          <td>
            {item.name}
            {item.date ? <div className="date">{item.date}</div> : null}
          </td>
          <td className="ion-text-end">
            {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
              .format(item.price)
              .replace(/^(\D+)/, "$1 ")
              .replace(/\s+/, " ")}
          </td>
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr className="dashed">
        <td colSpan={4} />
      </tr>
      <tr className="sub-total-order">
        <td colSpan={3}>Subtotal Order</td>
        <td className="ion-text-end">
          {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
            .format(props.subTotal)
            .replace(/^(\D+)/, "$1 ")
            .replace(/\s+/, " ")}
        </td>
      </tr>
    </tfoot>
  </table>
);

export default PaymentDetail;

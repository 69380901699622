import type { FC } from "react";
import type { PaymentTotalType } from "../types/components";
import "../styles/components/payment-total.css";

const PaymentTotal: FC<PaymentTotalType> = (props) => (
  <table className="component-payment-total">
    <tbody>
      {props.detail.map((item, index) => (
        <tr key={index}>
          <td colSpan={item.amount === null ? 4 : 3} style={{ color: item.is_discount ? "var(--ion-color-medium)" : "var(--ion-color-dark)" }}>
            {item.name}
          </td>
          {item.amount !== null ? (
            <td className="ion-text-end" style={{ color: item.is_discount ? "var(--ion-color-danger)" : "var(--ion-color-dark)" }}>
              {item.amount}
            </td>
          ) : null}
        </tr>
      ))}
      <tr className="dashed">
        <td colSpan={4} />
      </tr>
      <tr>
        <td colSpan={3}>
          <strong>GRAND TOTAL</strong>
        </td>
        <td className="ion-text-end">
          <strong>
            {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
              .format(props.grandTotal)
              .replace(/^(\D+)/, "$1 ")
              .replace(/\s+/, " ")}
          </strong>
        </td>
      </tr>
    </tbody>
  </table>
);

export default PaymentTotal;

import { type Dispatch, type SetStateAction, createContext } from "react";
import type { Action as CartAction, Cart, Product, Service } from "./types/cart";
import type { Checkout } from "./types/checkout";
import type { PaymentConfirmation } from "./types/transaction";

export default createContext(
  {} as {
    authentication: {
      handle: {
        check: (phone: string, callback: (register: boolean) => void) => Promise<void>;
        request: (name: string, phone: string) => Promise<void>;
      };
      phoneNumber: string;
      resetPhoneNumber: () => void;
      token: { access: string; expired: number; type: string };
    };
    cart: {
      context: {
        addProduct: (product: Product) => void;
        addService: (service: Service) => void;
        decreaseProduct: (product: Product) => void;
        decreaseService: (service: Service) => void;
        increaseProduct: (product: Product) => void;
        increaseService: (service: Service) => void;
        removeProduct: (product: Product) => void;
        removeService: (service: Service) => void;
        reset: () => void;
        setProductQuantity: (product: Product, value: number) => void;
        setServiceQuantity: (service: Service, value: number) => void;
      };
      dispatch: (value: CartAction) => void;
      state: Cart;
      value: {
        disabledCheckout: boolean;
        productChecked: (product: Product) => boolean;
        productQuantity: (product: Product) => number;
        serviceChecked: (service: Service) => boolean;
        serviceQuantity: (service: Service) => number;
      };
    };
    checkout: {
      handle: () => Promise<void>;
      loading: boolean;
      reset: () => void;
      state: Checkout;
    };
    code: string;
    promo: {
      code: string;
      handle: {
        cancel: () => Promise<void>;
        reset: () => void;
        use: (code: string) => Promise<void>;
      };
      loading: boolean;
    };
    setCode: Dispatch<SetStateAction<string>>;
    transaction: {
      handle: {
        new: (payment_detail: string, payment_type: string, callback: (id: string, redirect_url?: string) => void) => Promise<void>;
        reset: () => void;
      };
      id: null | number;
      loading: boolean;
      paymentConfirmation: PaymentConfirmation;
    };
  }
);

import { useEffect, useMemo, useReducer } from "react";
import { type Action, type Cart, type Product, type Service, Type } from "../types/cart";

const LOCAL_STORAGE_KEY = "cart";

const initialState = localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) as string) : { products: [], services: [] };

export default function useCart() {
  const [state, dispatch] = useReducer((previousState: Cart, action: Action) => {
    let newProductsState, newServicesState;
    switch (action.type) {
      case Type.ADD_PRODUCT:
        newProductsState = {
          ...previousState,
          products: [...previousState.products, { ...(action.payload?.product as Product), quantity: 1 }],
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newProductsState));
        return newProductsState;
      case Type.ADD_SERVICE:
        newServicesState = {
          ...previousState,
          services: [...previousState.services, { ...(action.payload?.service as Service), quantity: 1 }],
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newServicesState));
        return newServicesState;
      case Type.DECREASE_PRODUCT:
        if (Number(previousState.products.find((previousProduct) => previousProduct.id === action.payload?.product?.id)?.quantity) === 1) {
          newProductsState = {
            ...previousState,
            products: previousState.products.filter((previousProduct) => previousProduct.id !== action.payload?.product?.id),
          };
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newProductsState));
          return newProductsState;
        }
        newProductsState = {
          ...previousState,
          products: previousState.products.map((previousProduct) => {
            if (previousProduct.id === action.payload?.product?.id) return { ...previousProduct, quantity: Number(previousProduct.quantity) - 1 };
            return previousProduct;
          }),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newProductsState));
        return newProductsState;
      case Type.DECREASE_SERVICE:
        if (Number(previousState.services.find((previousService) => previousService.id === action.payload?.service?.id)?.quantity) === 1) {
          newServicesState = {
            ...previousState,
            services: previousState.services.filter((previousService) => previousService.id !== action.payload?.service?.id),
          };
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newServicesState));
          return newServicesState;
        }
        newServicesState = {
          ...previousState,
          services: previousState.services.map((previousService) => {
            if (previousService.id === action.payload?.service?.id) return { ...previousService, quantity: Number(previousService.quantity) - 1 };
            return previousService;
          }),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newServicesState));
        return newServicesState;
      case Type.INCREASE_PRODUCT:
        if (previousState.products.find((previousProduct) => previousProduct.id === action.payload?.product?.id) === undefined) {
          newProductsState = {
            ...previousState,
            products: [...previousState.products, { ...(action.payload?.product as Product), quantity: 1 }],
          };
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newProductsState));
          return newProductsState;
        }
        newProductsState = {
          ...previousState,
          products: previousState.products.map((previousProduct) => {
            if (previousProduct.id === action.payload?.product?.id) return { ...previousProduct, quantity: Number(previousProduct.quantity) + 1 };
            return previousProduct;
          }),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newProductsState));
        return newProductsState;
      case Type.INCREASE_SERVICE:
        if (previousState.services.find((previousService) => previousService.id === action.payload?.service?.id) === undefined) {
          newServicesState = {
            ...previousState,
            services: [...previousState.services, { ...(action.payload?.service as Service), quantity: 1 }],
          };
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newServicesState));
          return newServicesState;
        }
        newServicesState = {
          ...previousState,
          services: previousState.services.map((previousService) => {
            if (previousService.id === action.payload?.service?.id) return { ...previousService, quantity: Number(previousService.quantity) + 1 };
            return previousService;
          }),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newServicesState));
        return newServicesState;
      case Type.REMOVE_PRODUCT:
        newProductsState = {
          ...previousState,
          products: previousState.products.filter((previousProduct) => previousProduct.id !== action.payload?.product?.id),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newProductsState));
        return newProductsState;
      case Type.REMOVE_SERVICE:
        newServicesState = {
          ...previousState,
          services: previousState.services.filter((previousService) => previousService.id !== action.payload?.service?.id),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newServicesState));
        return newServicesState;
      case Type.RESET_STATE:
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ products: [], services: [] }));
        return { ...previousState, products: [], services: [] };
      case Type.SET_PRODUCT_QUANTITY:
        newProductsState = {
          ...previousState,
          products: previousState.products.map((previousProduct) => {
            if (previousProduct.id === action.payload?.product?.id) return { ...previousProduct, quantity: Number(action.payload.value) };
            return previousProduct;
          }),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newProductsState));
        return newProductsState;
      case Type.SET_SERVICE_QUANTITY:
        newServicesState = {
          ...previousState,
          services: previousState.services.map((previousService) => {
            if (previousService.id === action.payload?.service?.id) return { ...previousService, quantity: Number(action.payload.value) };
            return previousService;
          }),
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newServicesState));
        return newServicesState;
      default:
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(previousState));
        return previousState;
    }
  }, initialState);

  const context = useMemo(
    () => ({
      addProduct: (product: Product) => dispatch({ payload: { product }, type: Type.ADD_PRODUCT }),
      addService: (service: Service) => dispatch({ payload: { service }, type: Type.ADD_SERVICE }),
      decreaseProduct: (product: Product) => dispatch({ payload: { product }, type: Type.DECREASE_PRODUCT }),
      decreaseService: (service: Service) => dispatch({ payload: { service }, type: Type.DECREASE_SERVICE }),
      increaseProduct: (product: Product) => dispatch({ payload: { product }, type: Type.INCREASE_PRODUCT }),
      increaseService: (service: Service) => dispatch({ payload: { service }, type: Type.INCREASE_SERVICE }),
      removeProduct: (product: Product) => dispatch({ payload: { product }, type: Type.REMOVE_PRODUCT }),
      removeService: (service: Service) => dispatch({ payload: { service }, type: Type.REMOVE_SERVICE }),
      reset: () => dispatch({ type: Type.RESET_STATE }),
      setProductQuantity: (product: Product, value: number) => dispatch({ payload: { product, value }, type: Type.SET_PRODUCT_QUANTITY }),
      setServiceQuantity: (service: Service, value: number) => dispatch({ payload: { service, value }, type: Type.SET_SERVICE_QUANTITY }),
    }),
    []
  );

  const value = useMemo(
    () => ({
      disabledCheckout: state.products.length < 1 && state.services.length < 1,
      productChecked: (product: Product) => state.products.find((previousProduct) => previousProduct.id === product.id) !== undefined,
      productQuantity: (product: Product) => state.products.find((previousProduct) => previousProduct.id === product.id)?.quantity || 0,
      serviceChecked: (service: Service) => state.services.find((previousService) => previousService.id === service.id) !== undefined,
      serviceQuantity: (service: Service) => state.services.find((previousService) => previousService.id === service.id)?.quantity || 0,
    }),
    [state.products, state.services]
  );

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(initialState));
  }, []);

  return { context, dispatch, state, value };
}

import type { FC } from "react";
import { IonImg, IonSkeletonText, IonText } from "@ionic/react";
import type { CustomerListType } from "../types/components";
import "../styles/components/customer-list.css";

const CustomerList: FC<CustomerListType> = (props) => (
  <section className="component-customer-list">
    <IonText mode="md">
      <h2>{props.title}</h2>
      {props.action}
    </IonText>
    <ul className={props.list?.length !== 0 ? "active" : "normal"}>
      {props.isError || props.isLoading ? (
        new Array(props.numberOfPreloaders).fill("").map((_, index) => (
          <li key={index}>
            <IonSkeletonText animated={true} />
          </li>
        ))
      ) : props.list?.length === 0 ? (
        <li className="empty">
          <IonImg alt={props.empty.title} src={props.empty.iconSource} title={props.empty.title} />
          <IonText mode="md">
            <p>{props.empty.title}</p>
          </IonText>
        </li>
      ) : (
        props.list?.map((item, index) => item.queue_code ? (
          <li key={index}>
            <IonText mode="md">{item.queue_code}</IonText>
          </li>
        ) : (
          <li key={index}>
            <IonText mode="md"><span>{item.name}</span></IonText>
            <IonText mode="md"><p>{item.products}</p></IonText>
          </li>
        ))
      )}
    </ul>
  </section>
);

export default CustomerList;

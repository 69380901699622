/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./styles/global.css";
import { ElementType, useContext } from "react";
import { Redirect, Route, type RouteComponentProps, type RouteProps } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ApplicationContext from "./Context";
import useAuthentication from "./hooks/authentication";
import useCart from "./hooks/cart";
import useCheckout from "./hooks/checkout";
import usePromo from "./hooks/promo";
import useTransaction from "./hooks/transaction";
import useLocalStorage from "./utils/localstorage";
import ConfirmationOrder from "./views/ConfirmationOrder";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import PaymentMethod from "./views/PaymentMethod";
import PromoCode from "./views/PromoCode";
import QRCode from "./views/QRCode";
import Receipt from "./views/Receipt";
import Splash from "./views/Splash";

setupIonicReact();

function CheckoutRoute({ component: Component, ...props }: { component: ElementType<RouteComponentProps> } & RouteProps) {
  const { code, transaction } = useContext(ApplicationContext);
  return (
    <Route
      {...props}
      render={(routeProps) =>
        code !== "" && transaction.id !== null ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={{ pathname: `/${code}`, state: { from: routeProps.location } }} />
        )
      }
    />
  );
}

function OrderRoute({ component: Component, ...props }: { component: ElementType<RouteComponentProps> } & RouteProps) {
  const { code, checkout } = useContext(ApplicationContext);
  return (
    <Route
      {...props}
      render={(routeProps) =>
        code !== "" && Object.keys(checkout.state).length !== 0 ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={{ pathname: `/${code}`, state: { from: routeProps.location } }} />
        )
      }
    />
  );
}

export default function App() {
  const [code, setCode] = useLocalStorage<string>("outlet-code", "");
  const authentication = useAuthentication();
  const cart = useCart();
  const checkout = useCheckout(authentication, cart.state, code);
  const promo = usePromo(authentication, checkout.state, checkout.handle, code);
  const transaction = useTransaction(authentication, checkout.state, code);

  return (
    <ApplicationContext.Provider value={{ authentication, cart, checkout, code, promo, setCode, transaction }}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route component={Splash} exact path="/" />
            <Route exact path="/:code" render={() => (code !== "" ? <Home /> : <Redirect to="/" />)} />
            <Route component={NotFound} path="/:code/*" />
            <OrderRoute component={ConfirmationOrder} path="/:code/confirmation-order" />
            <OrderRoute component={PromoCode} path="/:code/promo-code" />
            <OrderRoute component={PaymentMethod} path="/:code/payment-method" />
            <CheckoutRoute component={QRCode} path="/:code/qr-code" />
            <Route component={Receipt} path="/:code/receipt/:receipt_number" />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </ApplicationContext.Provider>
  );
}

export enum Type {
  ADD_PRODUCT = "ADD_PRODUCT",
  ADD_SERVICE = "ADD_SERVICE",
  DECREASE_PRODUCT = "DECREASE_PRODUCT",
  DECREASE_SERVICE = "DECREASE_SERVICE",
  INCREASE_PRODUCT = "INCREASE_PRODUCT",
  INCREASE_SERVICE = "INCREASE_SERVICE",
  REMOVE_PRODUCT = "REMOVE_PRODUCT",
  REMOVE_SERVICE = "REMOVE_SERVICE",
  RESET_STATE = "RESET_STATE",
  SET_PRODUCT_QUANTITY = "SET_PRODUCT_QUANTITY",
  SET_SERVICE_QUANTITY = "SET_SERVICE_QUANTITY",
}

export type Action = {
  type: Type;
  payload?: {
    product?: Product;
    service?: Service;
    value?: number;
  };
};

export type Cart = {
  products: Array<Product>;
  services: Array<Service>;
};

export type Product = {
  code: string;
  description: null | string;
  id: number;
  id_brand: number;
  name: string;
  photo_url: string;
  price: number;
  quantity?: number;
  status: "Available" | "Sold Out";
  stock: number;
  type: "product" | "service";
};

export type Service = {
  code: string;
  description: null | string;
  id: number;
  id_brand: number;
  name: string;
  photo_url: string;
  price: number;
  quantity?: number;
  status: "Available" | "Sold Out";
  type: "product" | "service";
};

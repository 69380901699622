import { useContext, useEffect, useState } from "react";
import { IonContent, IonHeader, IonImg, IonInput, IonModal, IonPage, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import ApplicationContext from "../Context";
import "../styles/views/splash.css";

export default function Splash() {
  const { code, setCode } = useContext(ApplicationContext);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setTimeout(() => (code === "" ? setOpenModal(true) : history.replace(`/${code}`)), 2000);
  }, [code, history]);

  return (
    <IonPage className="view-splash">
      <IonContent className="ion-padding">
        <div className="container">
          <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
          <IonText className="ion-text-center" mode="md">
            <h1>Point Of Sales</h1>
          </IonText>
        </div>
        <IonModal backdropDismiss={false} id="outlet-code-modal" isOpen={openModal} mode="md">
          <IonHeader mode="md">
            <IonToolbar>
              <IonTitle className="ion-text-center">
                <h2>Masukkan Kode Outlet</h2>
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="ion-padding">
            <IonInput
              aria-label="Kode Outlet"
              autofocus
              enterkeyhint="enter"
              minlength={2}
              maxlength={11}
              mode="md"
              name="outlet-code"
              onBlur={(event) => {
                if (event.target.value !== "") {
                  setOpenModal(false);
                  setCode(event.target.value as string);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setOpenModal(false);
                }
              }}
              placeholder="OUT2201****"
              required={true}
              style={{ minHeight: "2.75rem" }}
              type="text"
            />
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
}

import type { FC } from "react";
import { IonCol, IonGrid, IonImg, IonItem, IonLabel, IonRow, IonSkeletonText, IonText, IonThumbnail } from "@ionic/react";
import type { HairstylistsType } from "../types/components";
import "../styles/components/hairstylists.css";

const Hairstylists: FC<HairstylistsType> = (props) => (
  <section className="component-hairstylists">
    <IonText mode="md">
      <h2>{props.title}</h2>
    </IonText>
    <IonGrid style={{ "--ion-grid-padding": 0 }}>
      <IonRow>
        {props.isError || props.isLoading ? (
          new Array(props.numberOfPreloaders).fill("").map((_, index) => (
            <IonCol key={index} size="12" sizeSm="6">
              <IonItem lines="none">
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true} />
                </IonThumbnail>
                <IonLabel className="ion-text-wrap">
                  <IonText mode="md">
                    <h3>
                      <IonSkeletonText animated={true} />
                    </h3>
                  </IonText>
                  <IonText mode="md">
                    <h4>
                      <IonSkeletonText animated={true} />
                    </h4>
                  </IonText>
                  <IonText mode="md">
                    <strong>
                      <IonSkeletonText animated={true} />
                    </strong>
                  </IonText>
                  <IonText mode="md">
                    <p>
                      <IonSkeletonText animated={true} className="ion-margin-top" />
                    </p>
                  </IonText>
                </IonLabel>
              </IonItem>
            </IonCol>
          ))
        ) : props.hairstylists?.length === 0 ? (
          <IonCol className="empty" size="12">
            <IonImg alt={props.empty.title} src={props.empty.iconSource} title={props.empty.title} />
            <IonText mode="md">
              <p>{props.empty.title}</p>
            </IonText>
          </IonCol>
        ) : (
          props.hairstylists?.map((hairstylist, index) => (
            <IonCol key={index} size="12" sizeSm="6" style={{ opacity: hairstylist.current_service !== null || hairstylist.status ? 1 : 0.5 }}>
              <IonItem className="ion-no-padding" lines="none">
                <IonThumbnail slot="start">
                  <IonImg alt={hairstylist.name} src={hairstylist.photo_url} />
                </IonThumbnail>
                <IonLabel className="ion-no-margin ion-text-wrap">
                  <IonText mode="md">
                    <h3>{hairstylist.name}</h3>
                  </IonText>
                  <IonText mode="md">
                    <h4>{hairstylist.shift_time}</h4>
                  </IonText>
                  {hairstylist.current_service !== null ? (
                    <IonText className="ion-align-items-center" mode="md" style={{ display: "flex" }}>
                      <IonImg alt={hairstylist.current_service} src="assets/icons/barbershop.png" title={hairstylist.current_service} />
                      <IonText color="secondary" mode="md">
                        <strong>{hairstylist.current_service}</strong>
                      </IonText>
                    </IonText>
                  ) : (
                    <IonText color={hairstylist.status ? "success" : "danger"} mode="md">
                      <strong>{hairstylist.status ? "Available" : "Off"}</strong>
                    </IonText>
                  )}
                  {hairstylist.end_shift !== null && hairstylist.status ? (
                    <IonText mode="md">
                      <p>{hairstylist.end_shift}</p>
                    </IonText>
                  ) : null}
                </IonLabel>
              </IonItem>
            </IonCol>
          ))
        )}
      </IonRow>
    </IonGrid>
  </section>
);

export default Hairstylists;

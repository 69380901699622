import { Suspense, useContext } from "react";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonSkeletonText, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import ApplicationContext from "../Context";
import "../styles/views/not-found.css";

export default function NotFound() {
  const { code } = useContext(ApplicationContext);

  return (
    <IonPage className="view-not-found">
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/${code}`} icon={arrowBack} mode="md" />
          </IonButtons>
          <IonTitle>
            <Suspense fallback={<IonSkeletonText animated={true} />}>
              <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
            </Suspense>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonImg alt="Spray" src="assets/images/spray.png" title="Spray" />
        <IonImg alt="Comb" src="assets/images/comb.png" title="Comb" />
        <IonImg alt="Chair" src="assets/images/chair.png" title="Chair" />
        <IonImg alt="Spread" src="assets/images/spread.png" title="Spread" />
        <IonImg alt="Shaver" src="assets/images/shaver.png" title="Shaver" />
        <IonImg alt="Broom" src="assets/images/broom.png" title="Broom" />
        <div className="container">
          <IonImg alt="Page not found" src="assets/images/not-found.png" title="Page not found" />
          <h2>404</h2>
          <p>Page not found</p>
        </div>
      </IonContent>
    </IonPage>
  );
}
